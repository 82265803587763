
import TextField from '@mui/material/TextField';
import React, {useState, useEffect, useContext, createContext, useRef} from "react";
import Badge from 'react-bootstrap/Badge'
import SaveIcon from '@mui/icons-material/Save';
import HubIcon from '@mui/icons-material/Hub';
import 'bootstrap/dist/css/bootstrap.min.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
import EditIcon from '@mui/icons-material/Edit';
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';

import Fade from '@mui/material/Fade';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// import './documents.css'
import {CircularProgress} from "@mui/material";
import {AppContext} from "../../../App";
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {alpha, styled} from "@mui/material/styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



export default function QuickPredicateModal(props) {

    const {
        username,
        users,predicateconcepts,predicatetext,
        collectionslist,
        document_id,
        concepts,
        collection,predicate,
        mentions,documentdescription,
        mentiontohighlight,
        startrange,
        endrange
    } = useContext(AppContext);
    const [PredicateText,SetPredicateText] = predicatetext
    const [Predicate,SetPredicate] = predicate
    const [MentionsInvolved,SetMentionsInvolved] = useState([])
    const [value,SetValue] = useState(0)
    const [SelectedMention,SetSetSelectedMention] = useState(false)
    const [MentionToHighlight, SetMentionToHighlight] = mentiontohighlight
    const [DocumentID, SetDocumentID] = document_id
    const [Collection, SetCollection] = collection
    const [MentionsList, SetMentionsList] = mentions
    const [Start, SetStart] = startrange
    const [End, SetEnd] = endrange
    const [ConceptsList, SetConceptsList] = concepts
    const [ShowAddConceptModal, SetShowAddConceptModal] = useState(false)
    const [contextMenu, setContextMenu] = useState(null);
    const inputEl = useRef(null);
    const [DocumentDesc,SetDocumentDesc] = documentdescription
    const [PredicateConcepts,SetPredicateConcepts] = predicateconcepts




    function addPredicate(e){
        e.preventDefault()
        e.stopPropagation()
        var type = "Relation predicate"
        var name = document.getElementById('predicate_string').value
        let concept = {concept_url: name,concept_name:name,concept_area:type,concept_description:null}
        let predicates = PredicateConcepts ? PredicateConcepts : []
        let new_list = [...predicates,concept]
        SetPredicateConcepts(new_list)
        SetPredicateText(name)
        props.setshowconceptmodal(false)
    }




    function handleClose(e){
        e.stopPropagation()
        e.preventDefault()
        props.setshowconceptmodal(false)
    }



    return (
        <Dialog
            open={props.showconceptmodal}
            onClose={handleClose}
            maxWidth={'sm'}
            fullWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle id="alert-dialog-title">
                Add a predicate
            </DialogTitle>
            <DialogContent>

                    <DialogContentText id="alert-dialog-description">
                Type a predicate. This predicate will be available as a concept under the type:"Relation Predicates".
                <br/><br/>
                        <TextField id="predicate_string" sx={{width: "100%"}} label="Predicate" variant="outlined" />

                    </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button onClick={(e)=>{handleClose(e)}}>No</Button>
                <Button onClick={(e)=>addPredicate(e)}>Confirm</Button>


            </DialogActions>
        </Dialog>
    );
}